// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-agencies-js": () => import("/opt/build/repo/src/pages/agencies.js" /* webpackChunkName: "component---src-pages-agencies-js" */),
  "component---src-pages-amazon-tool-bundles-js": () => import("/opt/build/repo/src/pages/amazon-tool-bundles.js" /* webpackChunkName: "component---src-pages-amazon-tool-bundles-js" */),
  "component---src-pages-conferences-and-meetups-js": () => import("/opt/build/repo/src/pages/conferences-and-meetups.js" /* webpackChunkName: "component---src-pages-conferences-and-meetups-js" */),
  "component---src-pages-courses-js": () => import("/opt/build/repo/src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-freelancers-js": () => import("/opt/build/repo/src/pages/freelancers.js" /* webpackChunkName: "component---src-pages-freelancers-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-podcasts-js": () => import("/opt/build/repo/src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-software-js": () => import("/opt/build/repo/src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

